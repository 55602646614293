<template>
  <div>
    <v-card>
      <v-card-text>
        <v-card
          flat
          >
          <v-card-title
            class="justify-center"
            >
            Foto de perfil
          </v-card-title>
          <v-card-text>
            <profile-picture
              :profile="currentUser.selectedProfile"
              @reload="$router.go()"
              ></profile-picture>
          </v-card-text>
        </v-card>

        <about-us
          :store="user.store"
          ></about-us>

        <banners
          :user="user"
          version="desktop"
          ></banners>

        <banners
          class="mt-3"
          :user="user"
          version="mobile"
          ></banners>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfilePicture from '@/components/profiles/ProfilePicture'

const AboutUs = () => import('./AboutUs')
const Banners = () => import('./customize/Banners')

export default {
  computed: {
    ...mapGetters(['currentUser'])
  },

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  components: {
    ProfilePicture, AboutUs, Banners
  },
}
</script>

<style scoped>
>>>[class^='col'] {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
